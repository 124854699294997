<template>
  <div class="registered">
    <div class="registered-cont">
      <div class="logo">
        <!-- <img @click="goIndex" src="../../../public/icon/login/logo.png" alt=""> -->
        <svg class="icon logo-black" aria-hidden="true" style="width:2em">
          <use xlink:href="#icon-sjmjlogo"></use>
        </svg>
      </div>
      <div class="phone-item">
        <img src="../../../public/icon/xin.png" alt="" />
        <div class="phone">
          <input
            v-model="phone"
            oninput="if(value.length>15)value=value.slice(0,15)"
            type="text"
            placeholder="请输入手机号码"
          />
        </div>
      </div>
      <div class="code-item">
        <img src="../../../public/icon/xin.png" alt="" />
        <div class="code-in">
          <div class="code">
            <input v-model="code" type="text" placeholder="请输入短信验证码" />
          </div>
          <div class="code-btn" @click="getCode">
            {{ stateCode }}
          </div>
        </div>
      </div>
      <div class="password-item">
        <img src="../../../public/icon/xin.png" alt="" />
        <div class="password">
          <input
            v-model="password"
            pattern="/(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{4,23}/"
            oninput="if(value.length>20)value=value.slice(0,20)"
            type="password"
            placeholder="请输入你要设置的密码"
          />
        </div>
      </div>
      <div class="password-again-item">
        <img src="../../../public/icon/xin.png" alt="" />
        <div class="password-again">
          <input
            v-model="passwordAgain"
            pattern="/(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{4,23}/"
            oninput="if(value.length>20)value=value.slice(0,20)"
            type="password"
            placeholder="请确认密码"
          />
        </div>
      </div>
      <div class="city-item">
        <img src="../../../public/icon/xin.png" alt="" />
        <div class="city" @click="getCity('province', 0)">
          <span>{{ cityName }}</span>
          <img
            :class="selectCityDef ? 'triangle' : ''"
            src="../../../public/icon/cdown.jpg"
            alt=""
          />
        </div>
        <div class="select-city" v-show="selectCityDef">
          <div class="province-cont">
            <span
              :class="provinceDef == item.AREAID ? 'activeProvince' : ''"
              @click="getCity('city', item.AREAID, item.AREANAME)"
              v-for="(item, index) in provinceList"
              :key="index"
              >{{ item.AREANAME }}</span
            >
          </div>
          <div class="city-cont">
            <div class="city-in">
              <span
                @click="selectCity(item)"
                @mouseenter="cityDef = item.AREAID"
                @mouseleave="cityDef = ''"
                :class="cityDef == item.AREAID ? 'activeCity' : ''"
                v-for="(item, index) in cityList"
                :key="index"
                >{{ item.AREANAME }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="referees-item">
        <input placeholder="请输入推荐人手机号码" type="text" />
      </div>
      <div class="agreement-item">
        <el-checkbox v-model="checked"></el-checkbox>
        <div>
          我已阅读并同意<span @click="agreementShow('service')">《平台服务协议》</span>和<span @click="agreementShow('privacy')">《胜捷美家隐私协议》</span>
        </div>
      </div>
      <div class="registered-btn">
        <el-button type="primary" @click="registerNow">立即注册</el-button>
      </div>
      <div class="login-immediately">
        <span class="existing">已经有账号？</span>
        <span class="to-login" @click="toLogin">马上登录></span>
      </div>
    </div>
    <el-dialog
    :title="agreementTitle"
    :visible.sync="dialogVisible"
    width="60%">
    <div class="Agreement-cont">
      <div class="title-item" v-for="(item,index) in agreementList" :key="index">
        <span class="title">{{item.title}}</span>
        <div class="secondaryTxt-item" v-for="(txtItem,txtIndex) in item.list" :key="txtIndex">
          <span class="secondaryTxt">{{txtItem.secondaryTxt}}</span>
          <div class="threeTxt-item" v-for="(it,ind) in txtItem.list" :key="ind">
            <span class="threeTxt">{{it.threeTxt}}</span>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false;checked=true">同 意</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
import dump from "@/util/dump";
export default {
  data() {
    return {
      stateCode: "获取验证码", //验证码获取按钮
      provinceList: [], //省
      cityList: [], //市
      provinceDef: "", //省选中状态
      cityDef: "", //市选中状态
      cityId: "", //市id
      selectCityDef: false, //省市显示状态
      cityName: "请选择当前所在城市", //选中城市名字
      checked: false, //协议同意状态
      phone: "", //手机号
      registNum: "", //随机数
      code: "", //验证码
      password: "", //密码
      passwordAgain: "", //再次填写密码
      referrerPhone: "", //推荐人手机号
      agreementTitle: "胜捷美家商城平台服务协议",
      dialogVisible:false,
      agreementList:[],
      
    };
  },
  methods: {
    //立即注册
    registerNow() {
      const that = this;
      if (!that.phone) {
        that.$message({
          message: "手机号不能为空！",
          type: "error",
        });
        return false;
      }
      if (!that.code) {
        that.$message({
          message: "验证码不能为空！",
          type: "error",
        });
        return false;
      }
      if (!that.registNum) {
        that.$message({
          message: "随机数不能为空！",
          type: "error",
        });
        return false;
      }
      if (!that.password) {
        that.$message({
          message: "密码不能为空！",
          type: "error",
        });
        return false;
      }
      if (!that.passwordAgain) {
        that.$message({
          message: "密码再次填写不能为空！",
          type: "error",
        });
        return false;
      }
      if (that.password != that.passwordAgain) {
        that.$message({
          message: "再次填写密码错误！",
          type: "error",
        });
        return false;
      }
      if (!that.provinceDef && !that.cityId) {
        that.$message({
          message: "城市不能为空！",
          type: "error",
        });
        return false;
      }
      if (!that.checked) {
        that.$message({
          message: "请勾选同意协议！",
          type: "error",
        });
        return false;
      }
      let para = {
        phone: that.phone,
        vcode: that.code,
        password: that.password,
        province_id: that.provinceDef,
        city_id: that.cityId,
        inviter_phone: that.referrerPhone,
      };
      that.$api.register(para).then((res) => {
        if (res.status == 100) {
          that.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            dump.link({
              type: 1,
              link: "/login",
              params: {
                id: 1,
              },
            });
          }, 2000);
        } else {
          that.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //获取随机数
    getRandomNum() {
      const that = this;
      that.$api.getRandomNum().then((res) => {
        if (res.status == 100) {
          that.registNum = res.data;
          that.getCodeData();
        }
      });
    },
    //获取验证码数据
    getCodeData() {
      const that = this;
      if (!that.phone) {
        that.$message({
          message: "手机号不能为空！",
          type: "error",
        });
        return false;
      }
      if (!that.registNum) {
        that.$message({
          message: "随机数不能为空！",
          type: "error",
        });
        return false;
      }

      that.$api
        .appRegister({
          phone: that.phone,
          registNum: that.registNum,
        })
        .then((res) => {
          if (res.status == 100) {
            that.timeLess();
            that.$message({
              message: res.msg,
              type: "success",
            });
            return false;
          } else {
            that.$message({
              message: res.msg,
              type: "error",
            });
            return false;
          }
        });
    },
    //获取验证码
    getCode() {
      const that = this;
      if (that.stateCode == "获取验证码") {
        that.getRandomNum();
      } else {
        that.$message({
          message: "请勿重复点击！",
          type: "error",
        });
      }
    },
    //获取验证码倒计时
    timeLess() {
      const that = this;
      let unit = "s";
      let timeData = 60;
      that.stateCode = timeData + unit;
      let time = setInterval(() => {
        timeData--;
        that.stateCode = timeData + unit;
        if (timeData == 0) {
          clearInterval(time);
          that.stateCode = "获取验证码";
        }
      }, 1000);
    },
    //获取省市区
    getCity(areaname, areaid, name) {
      const that = this;
      that.$api
        .cityList({
          areaname: areaname,
          areaid: areaid,
        })
        .then((res) => {
          if (res.status == 100) {
            if (areaname == "province") {
              that.provinceList = res.data.arealist;
              that.selectCityDef = !that.selectCityDef;
              that.provinceDef = res.data.arealist[0].AREAID;
              that.getCity(
                "city",
                that.provinceDef,
                res.data.arealist[0].AREANAME
              );
            } else {
              that.cityList = res.data.arealist;
              that.provinceDef = areaid;
              that.cityName = name;
            }
          }
        });
    },
    //选择城市
    selectCity(item) {
      const that = this;
      that.cityName = `${that.cityName}-${item.AREANAME}`;
      that.cityId = item.AREAID;
      that.selectCityDef = !that.selectCityDef;
    },
    //去登陆
    toLogin() {
      dump.link({
        type: 1,
        link: "/login",
        params: {
          id: 1,
        },
      });
    },
    //去首页
    goIndex() {
      dump.link({
        type: 1,
        link: "/",
      });
    },
    agreementShow(type){
      const that = this
      let serviceAgreement = [
        {
          title:'胜捷美家商城会员注册协议',
          list:[
            {
              secondaryTxt:'本协议是您与胜捷美家商城（网址：https://sjmeijia.cn/）所有者之间就胜捷美家商城服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击“同意以下协议，提交”按钮后，本协议即构成对双方有约束力的法律文件。',
              list:[]
            }
          ]
        },
        {
          title:'第1条 胜捷美家商城服务条款的确认和接纳',
          list:[
            {
              secondaryTxt:'1.1 本商城的各项电子服务的所有权和运作权归胜捷美家所有。用户同意所有注册协议条款并完成注册程序，才能成为胜捷美家的会员。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。',
              list:[]
            },
            {
              secondaryTxt:'1.2 用户点击同意本协议的，即视为会员确认自己具有享受胜捷美家商城服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。',
              list:[]
            },
            {
              secondaryTxt:'1.3 如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用胜捷美家商城。',
              list:[]
            },
            {
              secondaryTxt:'1.4 胜捷美家商城保留在中华人民共和国施行之法律允许的范围内独自决定拒绝服务、关闭会员账户、清除或编辑内容或取消订单的权利。',
              list:[]
            }
          ]
        },
        {
          title:'第2条 胜捷美家商城服务',
          list:[
            {
              secondaryTxt:'2.1 胜捷美家商城通过互联网依法为会员提供互联网信息等服务，会员在完全同意本协议及胜捷美家商城规定的情况下，方有权使用胜捷美家商城的相关服务。',
              list:[]
            },
            {
              secondaryTxt:'2.2 会员必须自行准备如下设备和承担如下开支：',
              list:[
                {
                  threeTxt:' （1） 上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；'
                },
                {
                  threeTxt:' （2） 上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。'
                }
              ]
            }
          ]
        },
        {
          title:'第3条 会员信息',
          list:[
            {
              secondaryTxt:'3.1 会员应自行诚信向胜捷美家商城提供注册资料，会员同意其提供的注册资料真实、准确、完整、合法有效，会员注册资料如有变动的，应及时更新其注册资料。如果会员提供的注册资料不合法、不真实、不准确、不详尽的，会员需承担因此引起的相应责任及后果，并且胜捷美家商城保留终止会员使用胜捷美家商城各项服务的权利。',
              list:[]
            },
            {
              secondaryTxt:'3.2 会员在胜捷美家商城进行浏览、下单购物等活动时，涉及会员真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，胜捷美家商城将予以严格保密，除非得到会员的授权或法律另有规定，胜捷美家商城不会向外界披露会员隐私信息。',
              list:[]
            },
            {
              secondaryTxt:'3.3 会员注册成功后，将产生会员名和密码等账户信息，您可以根据胜捷美家商城规定改变您的密码。会员应谨慎合理的保存、使用其会员名和密码。会员若发现任何非法使用会员账号或存在安全漏洞的情况，请立即通知胜捷美家商城并向公安机关报案。',
              list:[]
            },
            {
              secondaryTxt:'3.4 会员同意，胜捷美家商城拥有通过邮件、短信电话等形式，向在胜捷美家商城注册、购物会员、收货人发送订单信息、促销活动等告知信息的权利。',
              list:[]
            },
            {
              secondaryTxt:'3.5 会员不得将在胜捷美家商城注册获得的账户借给他人使用，否则会员应承担由此产生的全部责任，并与实际使用人承担连带责任。',
              list:[]
            },
            {
              secondaryTxt:'3.6 会员同意，胜捷美家商城有权使用会员的注册信息、会员名、密码等信息，登录进入会员的注册账户，进行证据保全，包括但不限于公证、见证等。',
              list:[]
            }
          ]
        },
        {
          title:'第4条 会员依法言行义务',
          list:[
            {
              secondaryTxt:'4.1 本协议依据国家相关法律法规规章制定，会员同意严格遵守以下义务：',
              list:[
                {
                  threeTxt:'（1） 不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；'
                },
                {
                  threeTxt:'（2） 从中国大陆向境外传输资料信息时必须符合中国有关法规；'
                },
                {
                  threeTxt:'（3） 不得利用胜捷美家商城从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；'
                },
                {
                  threeTxt:'（4） 不得干扰胜捷美家商城的正常运转，不得侵入胜捷美家商城及国家计算机信息系统；'
                },
                {
                  threeTxt:'（5） 不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；'
                },
                {
                  threeTxt:'（6） 不得教唆他人从事本条所禁止的行为；'
                },
                {
                  threeTxt:'（7） 不得利用在胜捷美家商城注册的账户进行牟利性经营活动；'
                },
                {
                  threeTxt:'（8） 不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容。'
                }
              ]
            },
            {
              secondaryTxt:'4.2 会员应不时关注并遵守胜捷美家商城不时公布或修改的各类合法规则规定。',
              list:[]
            },
            {
              secondaryTxt:'4.3 胜捷美家商城保有删除站内各类不符合法律政策或不真实的信息内容而无须通知会员的权利。',
              list:[]
            },
            {
              secondaryTxt:'4.4 若会员未遵守以上规定的，胜捷美家商城有权作出独立判断并采取暂停或关闭会员帐号等措施。会员须对自己在网上的言论和行为承担法律责任。',
              list:[]
            }
          ]
        },
        {
          title:'第5条 商品信息',
          list:[
            {
              secondaryTxt:'5.1 胜捷美家商城上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，胜捷美家商城不作特别通知。由于网站上商品信息的数量极其庞大，虽然胜捷美家商城会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，胜捷美家商城网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；胜捷美家商城欢迎纠错，并会视情况给予纠错者一定的奖励。为表述便利，商品和服务简称为“商品”或“货物”。',
              list:[]
            }
          ]
        },
        {
          title:'第6条 订单',
          list:[
            {
              secondaryTxt:'6.1 在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与会员本人不一致的，收货人的行为和意思表示视为会员的行为和意思表示，会员应对收货人的行为及意思表示的法律后果承担连带责任。',
              list:[]
            },
            {
              secondaryTxt:'6.2 除法律另有强制性规定外，双方约定如下：胜捷美家商城上销售方展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的合同要约；销售方收到您的订单信息后，只有在销售方将您在订单中订购的商品从仓库实际直接向您发出时（ 以商品出库为标志），方视为您与销售方之间就实际直接向您发出的商品建立了合同关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了合同关系；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立合同关系。您可以随时登录您在胜捷美家商城注册的账户，查询您的订单状态。',
              list:[]
            },
            {
              secondaryTxt:'6.3 由于市场变化及各种以及各种难以控制的因素的影响，胜捷美家商城无法保证您提交的订单信息中希望购买的商品都会有货；如您拟购买的商品，发生缺货，您有权取消订单。',
              list:[]
            }
          ]
        },
        {
          title:'第7条 配送',
          list:[
            {
              secondaryTxt:'7.1 胜捷美家将会把商品（货物）送到您所指定的收货地址，所有在胜捷美家商城上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。',
              list:[]
            },
            {
              secondaryTxt:'7.2 因如下情况造成订单延迟或无法配送等，销售方不承担延迟配送的责任：',
              list:[
                {
                  threeTxt:'（1） 会员提供的信息错误、地址不详细等原因导致的；'
                },
                {
                  threeTxt:'（2） 货物送达后无人签收，导致无法配送或延迟配送的；'
                },
                {
                  threeTxt:'（3） 情势变更因素导致的；'
                },
                {
                  threeTxt:'（4） 不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。'
                }
              ]
            }
          ]
        },
        {
          title:'第8条 所有权及知识产权条款',
          list:[
            {
              secondaryTxt:'8.1 会员一旦接受本协议，即表明该会员主动将其在任何时间段在胜捷美家商城发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给胜捷美家商城所有，会员同意胜捷美家商城有权就任何主体侵权而单独提起诉讼。',
              list:[]
            },
            {
              secondaryTxt:'8.2 本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于会员在胜捷美家商城上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。',
              list:[]
            },
            {
              secondaryTxt:'8.3 会员同意并已充分了解本协议的条款，承诺不将已发表于胜捷美家商城的信息，以任何形式发布或授权其它主体以任何方式使用（包括但限于在各类网站、媒体上使用）。',
              list:[]
            },
            {
              secondaryTxt:'8.4 胜捷美家商城是胜捷美家商城的制作者,拥有此网站内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及胜捷美家商城的内容进行修改，并在胜捷美家商城张贴，无须另行通知会员。在法律允许的最大限度范围内，胜捷美家商城对本协议及胜捷美家商城内容拥有解释权。',
              list:[]
            },
            {
              secondaryTxt:'8.5 除法律另有强制性规定外，未经胜捷美家商城明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用胜捷美家商城的信息内容，否则，胜捷美家商城有权追究其法律责任。',
              list:[]
            },
            {
              secondaryTxt:'8.6 胜捷美家商城所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是胜捷美家商城或其内容提供者的财产，受中国和国际版权法的保护。胜捷美家商城上所有内容的汇编是胜捷美家商城的排他财产，受中国和国际版权法的保护。胜捷美家商城上所有软件都是胜捷美家商城或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。',
              list:[]
            }
          ]
        },
        {
          title:'第9条 责任限制及不承诺担保',
          list:[
            {
              secondaryTxt:'9.1 除非另有明确的书面说明,胜捷美家商城及其所包含的或以其它方式通过胜捷美家商城提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。',
              list:[]
            },
            {
              secondaryTxt:'9.2 除非另有明确的书面说明,胜捷美家商城不对胜捷美家商城的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。',
              list:[]
            },
            {
              secondaryTxt:'9.3 胜捷美家商城不担保胜捷美家商城所包含的或以其它方式通过胜捷美家商城提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从林氏木业商城发出的电子信件、信息没有病毒或其他有害成分。',
              list:[]
            },
            {
              secondaryTxt:'9.4 如因不可抗力或其它胜捷美家商城无法控制的原因使胜捷美家商城销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，胜捷美家商城会合理地尽力协助处理善后事宜。',
              list:[]
            }
          ]
        },
        {
          title:'第10条 协议更新及会员关注义务',
          list:[
            {
              secondaryTxt:'10.1 根据国家法律法规变化及网站运营需要，胜捷美家商城有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在胜捷美家商城上即生效，并代替原来的协议。会员可随时登录查阅最新协议；会员有义务不时关注并阅读最新版的协议及网站公告。如会员不同意更新后的协议，可以且应立即停止接受胜捷美家商城依据本协议提供的服务；如会员继续使用本网站提供的服务的，即视为同意更新后的协议。胜捷美家建议您在使用胜捷美家商城之前阅读本协议及胜捷美家商城的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。',
              list:[]
            }
          ]
        },
        {
          title:'第11条 法律管辖和适用',
          list:[
            {
              secondaryTxt:'11.1 本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。 如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。',
              list:[]
            }
          ]
        },
        {
          title:'第12条 其他',
          list:[
            {
              secondaryTxt:'12.1 胜捷美家商城所有者是指在政府部门依法许可或备案的胜捷美家商城经营主体。',
              list:[]
            },
            {
              secondaryTxt:'12.2 胜捷美家商城尊重会员和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为会员和消费者提供服务。胜捷美家商城欢迎会员和社会各界提出意见和建议，胜捷美家商城将虚心接受并适时修改本协议及胜捷美家商城上的各类规则。',
              list:[]
            },
            {
              secondaryTxt:'12.3 本协议内容中以黑体、加粗等方式显著标识的条款，请会员着重阅读。',
              list:[]
            }
          ]
        }
      ]
      let privacyPolicy = [
        {
          title:'',
          list:[
            {
              secondaryTxt:'以下是本网站（https://sjmeijia.cn/）及其运营公司深圳市前海装点网络科技有限公司（以下合称“本网站”）的隐私规则条款。 您成为本网站用户前务必仔细阅读本隐私条款并同意本隐私条款。作为本网站服务的正常操作程序的一部分，本网站收集、使用并（在一些情况下）向第三方披露有关您的资料。本隐私条款作为本网站服务协议的附件，在您注册成为本网站用户后立即生效，并对您及本网站产生约束力。',
              list:[]
            }
          ]
        },
        {
          title:'一、用户身份限制',
          list:[
            {
              secondaryTxt:'未成年人（年龄18周岁以下人士）、限制行为能力人、中国（包括港澳台地区）以外地区人士及自然人以外的组织无资格注册成为本网站用户并使用本网站的服务，本网站要求未成年人、限制行为能力人、中国（包括港澳台地区）以外地区人士及各类组织不要向本网站提交任何个人资料。',
              list:[]
            }
          ]
        },
        {
          title:'二、涉及的个人资料',
          list:[
            {
              secondaryTxt:'本网站收集个人资料的主要目的在于向您提供一个顺利、有效和度身订造的交易经历。本网站仅收集本网站认为就此目的及达成该目的所必须的关于您的个人资料。',
              list:[
                {
                  threeTxt:' 本网站可能自公开及私人资料来源收集您的额外资料，以更好地了解本网站用户，并为其度身订造本网站服务、解决争议并有助确保在网站进行交易的安全性。'
                },
                {
                  threeTxt:' 本网站按照您在本网站网址上的行为自动追踪关于您的某些资料。本网站利用这些资料进行有关本网站之用户的人口统计、兴趣及行为的内部研究，以更好地了解您以便向您和本网站的用户社区提供更好的服务。'
                },
                {
                  threeTxt:' 本网站在本网站的某些网页上使用诸如“Cookies”的资料收集装置。“Cookies”是设置在您的硬盘上的小档案，以协助本网站为您提供度身订造的服务。本网站亦提供某些只能通过使用“Cookies”才可得到的功能。本网站还利用“Cookies”使您能够在某段期间内减少输入密码的次数。“Cookies”还可以协助本网站提供专门针对您的兴趣而提供的资料。'
                },
                {
                  threeTxt:' 如果您将个人通讯信息（例如：手机短信、电邮或信件）交付给本网站，或如果其他用户或第三方向本网站发出关于您在本网站上的活动或登录事项的通讯信息，本网站可以将这些资料收集在您的专门档案中。'
                }
              ]
            }
          ]
        },
        {
          title:'三、本网站对您的资料的使用',
          list:[
            {
              secondaryTxt:'您同意本网站可使用关于您的个人资料（包括但不限于本网站持有的有关您的档案中的资料，及本网站从您目前及以前在本网站上的活动所获取的其他资料）以解决争议、对纠纷进行调停、有助于确保在本网站进行安全交易，并执行本网站的用户协议。本网站有时候可能调查多个用户以识别问题或解决争议，特别是本网站可审查您的资料以区分使用多个用户名或别名的用户。 为限制在网站上的欺诈、非法或其他刑事犯罪活动，使本网站免受其害，您同意本网站可通过人工或自动程序对您的个人资料进行评价。',
              list:[
                {
                  threeTxt:'您同意本网站可以使用您的个人资料以改进本网站的推广和促销工作、分析网站的使用率、改善本网站的内容和产品推广形式，并使本网站的网站内容、设计和服务更能符合用户的要求。这些使用能改善本网站的网页，以调整本网站的网页使其更能符合您的需求，从而使您在使用本网站服务时得到更为顺利、有效、安全及度身订造的交易体验。'
                },
                {
                  threeTxt:'您同意本网站利用您的资料与您联络并（在某些情况下）向您传递针对您的兴趣而提供的信息，例如：有针对性的广告条、行政管理方面的通知、产品提供以及有关您使用本网站的通讯。您接受服务协议和隐私条款即为明示同意收取这些资料。'
                }
              ]
            }
          ]
        },
        {
          title:'四、本网站对您的资料的修改或删除',
          list:[
            {
              secondaryTxt:'您可以授权本网站帮助您修改您在本网站填写的一切个人资料。如您违反服务协议等本网站规则或法律规定，本网站有权经电子邮件告知后在网站数据库中删除您的个人资料、关闭账户或者限制您使用本网站。',
              list:[
                {
                  threeTxt:'网站有权根据实际审核结果在不通知您的情况下对您所填写的与事实不符的资料进行修正或更改。'
                }
              ]
            }
          ]
        },
        {
          title:'五、本网站对您的资料的披露',
          list:[
            {
              secondaryTxt:'本网站采用行业标准惯例以保护您的个人资料，但鉴于技术限制，本网站不能确保您的全部私人通讯及其他个人资料不会通过本隐私条款中未列明的途径泄露出去，对此本网站不承担任何责任。',
              list:[
                {
                  threeTxt:'本网站有权根据有关法律和监管要求，本网站风险控制要求以及相关协议要求向司法机关等政府部门、社会组织或团体、其他第三方服务或合作机构提供您的个人资料。在您未能按照与本网站签订的服务协议、居间协议或者与本网站企业用户签订的借款（担保）协议的约定履行自己应尽的义务时，本网站有权根据自己的判断或者与该笔交易有关的其他用户的请求披露您的个人资料，并作出评论。'
                }
              ]
            }
          ]
        },
        {
          title:'六、您对其他用户的资料的使用',
          list:[
            {
              secondaryTxt:'本网站采用行业标准惯例以保护您的个人资料，但鉴于技术限制，本网站不能确保您的全部私人通讯及其他个人资料不会通过本隐私条款中未列明的途径泄露出去，对此本网站不承担任何责任。<br />本网站有权根据有关法律和监管要求，本网站风险控制要求以及相关协议要求向司法机关等政府部门、社会组织或团体、其他第三方服务或合作机构提供您的个人资料。在您未能按照与本网站签订的服务协议、居间协议或者与本网站企业用户签订的借款（担保）协议的约定履行自己应尽的义务时，本网站有权根据自己的判断或者与该笔交易有关的其他用户的请求披露您的个人资料，并作出评论。',
              list:[
                {
                  threeTxt:'（1）您已向法院起诉其他用户的在本网站活动中的违约行为；'
                },
                {
                  threeTxt:'（2）本网站被吊销营业执照、解散、清算、宣告破产或者其他有碍于您收回借款本息的情形。'
                }
              ]
            }
          ]
        },
        {
          title:'七、电子邮件',
          list:[
            {
              secondaryTxt:'您不得使用本网站提供的服务或其他电子邮件转发服务发送垃圾邮件或其他可能影响本网站系统运行或违反本网站的用户协议或隐私条款的内容。',
              list:[
                {
                  threeTxt:'如果您利用本网站的服务向没有在本网站内注册的电子邮件地址发出电子邮件,本网站除了利用该电子邮件地址发出您的电子邮件之外将不作任何其他用途。本网站不会出租或出售这些电子邮件地址。本网站不会永久储存电子邮件信息或电子邮件地址。'
                }
              ]
            }
          ]
        },
        {
          title:'八、密码的安全性',
          list:[
            {
              secondaryTxt:'您须对使用您的用户名和密码所采取的一切行为负责。因此，本网站建议您不要向任何第三方披露您在本网站的用户名和密码。',
              list:[]
            }
          ]
        },
        {
          title:'九、规则修改',
          list:[
            {
              secondaryTxt:'本网站可能不时按照您的意见和本网站的需要修改本隐私条款，以准确地反映本网站的资料收集及披露惯例。本条款的所有修改，在本网站公布有关修改内容后自动生效或在该等条款指定的日期生效。',
              list:[]
            }
          ]
        }
      ]
      if(type == 'service'){
        that.agreementTitle = '胜捷美家商城平台服务协议'
        that.agreementList = serviceAgreement
      }else{
        that.agreementTitle = '胜捷美家隐私条款'
        that.agreementList = privacyPolicy
      }
      that.dialogVisible = !that.dialogVisible
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/view/registered/registered";
</style>
